<template>
  <div class="flex fixed top-0 left-0 justify-center items-center w-full h-full bg-grey-dark opacity-75 z-50">
    <!-- <div class="flex flex-col fixed top-0 left-0 justify-center items-center w-full h-full bg-grey-dark bg-opacity-75 z-50">
    <div class="bg-grey-mid dark:bg-grey-dark text-center rounded p-2 scale-90"> -->
    <LoadingIcon />
    <!-- <p class="text-magenta font-montserrat text-xs font-semibold mt-2" v-if="!empty($store.getters.getLoadingText)" v-html="$store.getters.getLoadingText" /> -->
    <!-- </div> -->
  </div>
</template>
<script>
import LoadingIcon from "@Components/layouts/LoadingIcon";
// import { empty } from "@/utils";
export default {
  components: {
    LoadingIcon,
  },
  // methods: {
  //   empty,
  // },
};
</script>
