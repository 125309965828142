<template>
  <div
    ref="toaster"
    class="absolute top-5 right-1 md:right-5 hidden opacity-0 z-50"
  >
    <div class="bg-white rounded-lg border-grey-light border p-3 shadow-lg">
      <div class="flex flex-row">
        <div class="flex items-center px-2">
          <SuccessIcon
            v-if="$store.state.auth.alert.status"
            class="h-12 w-12 text-success"
          />
          <ErrorIcon v-else class="h-12 w-12 fill-magenta" />
        </div>
        <div class="ml-2">
          <span class="font-semibold">{{ $store.state.auth.alert.title }}</span>
          <span class="block text-black-1">{{
            $store.state.auth.alert.message
          }}</span>
        </div>
        <div class="flex items-center md:pl-3">
          <CloseIcon
            class="h-8 w-8 text-grey-light cursor-pointer"
            @click="
              () =>
                $store.dispatch('toggleAlert', {
                  isAlert: false,
                })
            "
          />
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { ref } from "vue";

import { TweenMax } from "gsap";

import SuccessIcon from "@Assets/images/SuccessIcon";
import ErrorIcon from "@Assets/images/ErrorIcon";
import CloseIcon from "@Assets/images/CloseIcon";

export default {
  name: "Alert",
  components: {
    SuccessIcon,
    ErrorIcon,
    CloseIcon,
  },
  setup() {
    const toaster = ref(null);

    return {
      toaster,
    };
  },
  watch: {
    "$store.state.auth.alert.isAlert": async function (val) {
      if (val) {
        TweenMax.to(this.toaster, {
          scale: 1,
          duration: 0.5,
          ease: "ease-in",
          display: "block",
          opacity: 1,
        });
      } else {
        TweenMax.to(this.toaster, {
          scale: 0.95,
          duration: 0.5,
          ease: "ease-out",
          display: "none",
          opacity: 0,
        });
      }
    },
  },
};
</script>
