import Repository, { repositoryNoHeader } from "@API/repository";

export const getEvents = async () => {
  const url = "event";
  const response = await Repository({
    url,
    method: "GET",
  });
  return response.data;
};

export const getEvent = async payload => {
  const url = `event/${payload}`;
  const response = await Repository({
    url,
    method: "GET",
  });
  return response.data;
};

export const postEvent = async data => {
  const url = "event";
  const response = await Repository({
    url,
    method: "POST",
    data,
  });
  return response.data;
};

export const putEvent = async data => {
  const url = `event/${data.id}`;
  const response = await Repository({
    url,
    method: "PUT",
    data,
  });
  return response.data;
};

export const deleteEvent = async payload => {
  const url = `event/${payload}`;
  const response = await Repository({
    url,
    method: "DELETE",
  });
  return response.data;
};

export const getS3UploadKey = async type => {
  const url = `commons/s3-sign?ext=${type}`;
  const response = await Repository({
    url,
    method: "GET",
  });
  return response.data;
};

export const getUserList = async eventId => {
  const url = `user-chat/${eventId}`;
  const response = await Repository({
    url,
    method: "GET",
  });
  return response.data;
};

export const deleteUserList = async eventId => {
  const url = `user-chat/${eventId}`;
  const response = await Repository({
    url,
    method: "DELETE",
  });
  return response.data;
};

export const doUpdateStatusEvent = async (eventId, data) => {
  const url = `event/${eventId}/status`;
  const response = await Repository({
    url,
    method: "PUT",
    data,
  });
  return response.data;
};

export const addUserEventAccess = async (eventId, data) => {
  const url = `event/${eventId}/users?type=ADD`;
  const response = await Repository({
    url,
    method: "PUT",
    data,
  });
  return response.data;
};

export const deleteUserEventAccess = async (eventId, data) => {
  const url = `event/${eventId}/users?type=DEL`;
  const response = await Repository({
    url,
    method: "PUT",
    data,
  });
  return response.data;
};

export const getMonitorInfo = async eventId => {
  const url = `event/${eventId}/monitor`;
  const response = await Repository({
    url,
    method: "GET",
  });
  return response.data;
};

export const getTrackingInfo = async eventId => {
  const url = `event/${eventId}/tracking?time=500`;
  const response = await Repository({
    url,
    method: "GET",
  });
  return response.data;
};

export const getCustomers = async eventId => {
  const url = `event/${eventId}/customer`;
  const response = await Repository({
    url,
    method: "GET",
  });
  return response.data;
};

export const addCustomer = async (eventId, data) => {
  const url = `event/${eventId}/customer`;
  const response = await Repository({
    url,
    method: "POST",
    data,
  });
  return response.data;
};

export const updateCustomer = async (eventId, data) => {
  const url = `event/${eventId}/customer/${data.id}`;
  const response = await Repository({
    url,
    method: "PUT",
    data,
  });
  return response.data;
};

export const deleteCustomer = async (eventId, customerId) => {
  const url = `event/${eventId}/customer/${customerId}`;
  const response = await Repository({
    url,
    method: "DELETE",
  });
  return response.data;
};

export const deleteCustomers = async eventId => {
  const url = `event/${eventId}/customers`;
  const response = await Repository({
    url,
    method: "DELETE",
  });
  return response.data;
};

export const updateCssHelper = async data => {
  const url = `/commons/s3-css-help`;
  const response = await Repository({
    url,
    method: "POST",
    data,
  });
  return response.data;
};

export const getCssHelper = async () => {
  const url = `${process.env.VUE_APP_S3_URL}/css-help.json`;
  const response = await repositoryNoHeader({
    url,
    method: "GET",
  });
  return response.data;
};
