<template>
  <div class="flex fixed top-0 left-0 justify-center w-full h-full z-40">
    <div
      class="
        flex
        min-h-screen
        pt-4
        px-4
        pb-10
        sm:pb-20
        text-center
        sm:block sm:p-0
        w-full
        sm:max-w-lg
      "
      :class="customCSS.popupContainer"
    >
      <div class="fixed inset-0 transition-opacity" aria-hidden="true">
        <div class="absolute inset-0 bg-grey-dark opacity-75"></div>
      </div>

      <!-- This element is to trick the browser into centering the modal contents. -->
      <span
        class="hidden sm:inline-block sm:align-middle sm:h-screen"
        aria-hidden="true"
        >&#8203;</span
      >
      <div
        class="
          inline-block
          align-bottom
          bg-white
          dark:bg-dark-bgSecondary
          rounded-lg
          text-left
          shadow-xl
          transform
          transition-all
          sm:my-8 sm:align-middle
          w-full
        "
        role="dialog"
        aria-modal="true"
        aria-labelledby="modal-headline"
      >
        <div
          class="
            bg-white
            dark:bg-dark-bgSecondary
            px-4
            pt-5
            pb-4
            sm:p-6 sm:pb-4
            rounded-t-lg
          "
        >
          <div class="flex flex-col">
            <div class="flex items-center">
              <div
                class="
                  mx-auto
                  flex-shrink-0 flex
                  items-center
                  justify-center
                  h-12
                  w-12
                  rounded-full
                  bg-red-100
                  sm:mx-0 sm:h-10 sm:w-10
                "
              >
                <!-- Heroicon name: exclamation -->
                <svg
                  class="h-6 w-6 text-danger"
                  xmlns="http://www.w3.org/2000/svg"
                  fill="none"
                  viewBox="0 0 24 24"
                  stroke="currentColor"
                  aria-hidden="true"
                >
                  <path
                    stroke-linecap="round"
                    stroke-linejoin="round"
                    stroke-width="2"
                    d="M12 9v2m0 4h.01m-6.938 4h13.856c1.54 0 2.502-1.667 1.732-3L13.732 4c-.77-1.333-2.694-1.333-3.464 0L3.34 16c-.77 1.333.192 3 1.732 3z"
                  />
                </svg>
              </div>

              <h3
                class="
                  text-lg
                  leading-6
                  font-medium
                  text-gray-900
                  dark:text-dark-textPrimary
                  ml-3
                "
                id="modal-headline"
                v-html="confirmDialog.title"
              />
            </div>
            <div
              class="
                mt-3
                text-center
                sm:mt-0 sm:ml-4 sm:text-left
                text-gray-900
                dark:text-dark-textPrimary
                max-h-screen-2/3
                overflow-y-auto
              "
            >
              <div class="mt-2" :class="customCSS.contentContainer">
                <component
                  ref="component"
                  v-if="confirmDialog.component"
                  :is="confirmDialog.component"
                />
                <p v-else class="text-sm text-gray-500">
                  {{ confirmDialog.message }}
                </p>
              </div>
            </div>
          </div>
        </div>
        <div class="px-4 py-3 sm:px-6 sm:flex sm:flex-row-reverse">
          <button
            type="button"
            class="
              w-full
              inline-flex
              justify-center
              rounded-md
              border border-transparent
              shadow-sm
              px-4
              py-2
              bg-danger
              text-base
              font-medium
              text-white
              focus:outline-none
              focus:ring-2
              focus:ring-offset-2
              focus:ring-danger
              sm:ml-3 sm:w-auto sm:text-sm
            "
            @click="handleSubmit"
          >
            {{ confirmDialog.confirmButtonText }}
          </button>
          <button
            type="button"
            class="
              mt-3
              w-full
              inline-flex
              justify-center
              rounded-md
              border border-grey-mid
              shadow-sm
              px-4
              py-2
              bg-white
              text-base
              font-medium
              text-grey-dark
              focus:outline-none focus:ring-2 focus:ring-offset-2
              sm:mt-0 sm:ml-3 sm:w-auto sm:text-sm
            "
            @click="handleCancel"
          >
            Cancel
          </button>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "ConfirmDialog",
  props: {
    eventListen: {
      type: String,
      default: "submit-confirm-dialog",
    },
    customCSS: {
      type: Object,
      default: () => ({}),
    },
  },
  computed: {
    confirmDialog() {
      return this.$store.state.auth.confirmDialog;
    },
  },
  methods: {
    handleCancel() {
      this.$store.dispatch("resetConfirmDialog");
    },
    handleSubmit() {
      if (this.confirmDialog.component) {
        const result = this.$refs.component.doSubmit();
        if (result !== false) {
          this.emitter.emit(this.eventListen, result);
        }
      } else {
        this.emitter.emit(this.eventListen);
      }
    },
  },
};
</script>
