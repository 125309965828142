import axios from "axios";
import { includes } from "lodash";

import { getAccessToken } from "@Libs/hooks/useAuth";
import { logout } from "@Libs/hooks/useAuth";
import router from "@/router";
const errorCode = [403, 401];

export default async data => {
  const instance = axios.create({
    baseURL: process.env.VUE_APP_API_END_POINT,
    headers: {
      Authorization: `Bearer ${await getAccessToken()}`,
    },
  });

  instance.interceptors.response.use(
    response => response,
    error => {
      if (error.response) {
        if (includes(errorCode, error.response.status)) {
          logout();
          return router.push({
            name: "Login",
          });
        }
        return error.response;
      } else if (error.request) {
        return error.request;
      } else {
        return error.message;
      }
    },
  );

  return await instance(data);
};

export async function repositoryNoHeader(data) {
  const instance = axios.create({
    baseURL: process.env.VUE_APP_API_END_POINT,
  });

  instance.interceptors.response.use(
    response => response,
    error => {
      if (error.response) {
        if (includes(errorCode, error.response.status)) {
          logout();
          return router.push({
            name: "Login",
          });
        }
        return error.response;
      } else if (error.request) {
        return error.request;
      } else {
        return error.message;
      }
    },
  );

  return await instance(data);
}
