<template>
  <header
    class="
      flex
      h-16
      md:h-20
      justify-between
      items-center
      bg-transparent
      px-4
      md:px-7
      lg:px-11
    "
  >
    <div class="flex items-center">
      <button
        class="
          text-grey-dark
          dark:text-dark-textSecondary
          focus:outline-none
          mr-2.5
        "
        @click="isOpen = true"
      >
        <MenuIcon class="h-8 w-8" />
      </button>
    </div>

    <div class="flex items-center">
      <div
        v-if="['EventList', 'UserList'].indexOf(this.$route.name) !== -1"
        class="relative mr-5 lg:mr-8"
      >
        <span class="absolute inset-y-0 left-0 pl-3 flex items-center">
          <SearchIcon class="h-5 w-5 text-grey-dark" />
        </span>

        <input
          v-model="searchText"
          type="text"
          placeholder="Search"
          class="
            form-input
            md:w-96
            rounded-3xl
            border-none
            placeholder-grey-light
            dark:placeholder-black-1
            pl-10
            pr-4
            focus:ring-magenta focus:border-magenta
          "
          @input="handleInputChange"
        />
      </div>

      <div class="relative">
        <button
          class="
            relative
            z-10
            block
            h-9
            w-9
            rounded-full
            overflow-hidden
            focus:outline-none
          "
        >
          <UserIcon
            class="
              h-10
              w-10
              fill-current
              text-grey-dark
              dark:text-dark-textSecondary
            "
          />
        </button>
      </div>

      <h4
        class="
          hidden
          lg:block
          text-grey-dark
          dark:text-dark-textPrimary
          text-xl
          ml-4
        "
      >
        {{ $store.getters.getUserInfo.username }}
      </h4>
    </div>
  </header>
</template>

<script>
import { useSidebar } from "@Libs/hooks/useSidebar";

import MenuIcon from "@Assets/images/MenuIcon";
import SearchIcon from "@Assets/images/SearchIcon";
import UserIcon from "@Assets/images/UserIcon";

export default {
  name: "Header",
  components: {
    MenuIcon,
    SearchIcon,
    UserIcon,
  },
  setup() {
    const { isOpen } = useSidebar();

    return {
      isOpen,
    };
  },
  data() {
    return {
      searchText: "",
      debounce: null,
    };
  },
  methods: {
    handleInputChange(event) {
      clearTimeout(this.debounce);
      this.debounce = setTimeout(() => {
        this.searchText = event.target.value;
        this.emitter.emit("header-input-change", this.searchText);
      }, 700);
    },
  },
  watch: {
    $route(to) {
      // if (to.name === "EventList") {
      //   this.searchText = this.$store.state.event.eventSearchText;
      // } else if (to.name === "UserList") {
      //   this.searchText = this.$store.state.user.userSearchText;
      // }
    },
  },
};
</script>
