<template>
  <svg
    xmlns="http://www.w3.org/2000/svg"
    fill="currentColor"
    viewBox="0 0 486.8 486.8"
  >
    <path
      d="M80,370.406c-1.9-3.3-6.2-4.4-9.5-2.5l-17.4,10.1v-20.1c0-3.8-3.1-6.9-6.9-6.9c-3.8,0-6.9,3.1-6.9,6.9v20.1l-17.4-10.1
			c-3.3-1.9-7.6-0.8-9.5,2.5s-0.8,7.6,2.5,9.5l17.5,10.2l-17.5,10.2c-3.3,1.9-4.4,6.2-2.5,9.5s6.2,4.4,9.5,2.5l17.4-10.1v20.1
			c0,3.8,3.1,6.9,6.9,6.9c3.8,0,6.9-3.1,6.9-6.9v-20.1l17.4,10.1c3.3,1.9,7.6,0.8,9.5-2.5s0.8-7.6-2.5-9.5l-17.5-10.2l17.5-10.2
			C80.8,377.906,82,373.706,80,370.406z"
    />
    <path
      d="M143.9,409.706c1.9,3.3,6.2,4.4,9.5,2.5l17.4-10.1v20.1c0,3.8,3.1,6.9,6.9,6.9c3.8,0,6.9-3.1,6.9-6.9v-20.1l17.4,10.1
			c3.3,1.9,7.6,0.8,9.5-2.5s0.8-7.6-2.5-9.5l-17.5-10.2l17.5-10.2c3.3-1.9,4.4-6.2,2.5-9.5s-6.2-4.4-9.5-2.5l-17.4,10.1v-20.1
			c0-3.8-3.1-6.9-6.9-6.9c-3.8,0-6.9,3.1-6.9,6.9v20.1l-17.4-10c-3.3-1.9-7.6-0.8-9.5,2.5s-0.8,7.6,2.5,9.5l17.5,10.2l-17.5,10.1
			C143.1,402.106,142,406.406,143.9,409.706z"
    />
    <path
      d="M131.5,462.106v12.2c0,2.9,2.4,5.3,5.3,5.3h81.9c2.9,0,5.3-2.4,5.3-5.3v-12.2c0-2.9-2.4-5.3-5.3-5.3h-81.9
			C133.8,456.806,131.5,459.206,131.5,462.106z"
    />
    <path
      d="M331.1,121.106h-0.6v-27.2c0-47.3-38.7-87.5-85.9-86.7c-45.9,0.8-83.1,38.4-83.1,84.5v2.6c0,3.9,3.2,7.1,7.1,7.1h22.7
			c3.9,0,7.1-3.2,7.1-7.1v-0.8c0-25.4,19.1-47.7,44.5-49.3c27.6-1.7,50.6,20.2,50.6,47.4v29.4h-70v0.1h-64.7
			c-12,0.4-21.7,10.1-21.7,22.2v123c0,12.3,10,22.3,22.3,22.3H331c12.3,0,22.3-10,22.3-22.3v-122.9
			C353.4,131.106,343.4,121.106,331.1,121.106z M258.5,210.406c-1.7,1.2-2.2,2.5-2.2,4.5c0.1,9,0.1,18,0,27.1l0,0
			c0.2,3.8-1.7,7.4-5.1,9.1c-7.9,4-15.8-1.6-15.8-9.1c0,0,0,0,0-0.1c0-9,0-18.1,0-27.1c0-1.8-0.4-3.1-2-4.3
			c-8.2-6-10.9-16.3-6.8-25.4c4-8.8,13.7-14,22.8-12.1c10.2,2,17.3,10.3,17.4,20.4C267.1,200.506,264.2,206.306,258.5,210.406z"
    />
    <path
      d="M262.9,462.106v12.2c0,2.9,2.4,5.3,5.3,5.3h81.9c2.9,0,5.3-2.4,5.3-5.3v-12.2c0-2.9-2.4-5.3-5.3-5.3h-81.9
			C265.3,456.806,262.9,459.206,262.9,462.106z"
    />
    <path
      d="M275.3,409.706c1.9,3.3,6.2,4.4,9.5,2.5l17.4-10.1v20.1c0,3.8,3.1,6.9,6.9,6.9s6.9-3.1,6.9-6.9v-20.1l17.4,10.1
			c3.3,1.9,7.6,0.8,9.5-2.5s0.8-7.6-2.5-9.5l-17.5-10.2l17.5-10.2c3.3-1.9,4.4-6.2,2.5-9.5s-6.2-4.4-9.5-2.5l-17.4,10.1v-20.1
			c0-3.8-3.1-6.9-6.9-6.9s-6.9,3.1-6.9,6.9v20.1l-17.4-10.1c-3.3-1.9-7.6-0.8-9.5,2.5s-0.8,7.6,2.5,9.5l17.5,10.2l-17.5,10.2
			C274.5,402.106,273.4,406.406,275.3,409.706z"
    />
    <path
      d="M481.5,456.806h-81.9c-2.9,0-5.3,2.4-5.3,5.3v12.2c0,2.9,2.4,5.3,5.3,5.3h81.9c2.9,0,5.3-2.4,5.3-5.3v-12.2
			C486.8,459.206,484.4,456.806,481.5,456.806z"
    />
    <path
      d="M5.3,479.606h81.9c2.9,0,5.3-2.4,5.3-5.3v-12.2c0-2.9-2.4-5.3-5.3-5.3H5.3c-2.9,0-5.3,2.4-5.3,5.3v12.2
			C0,477.206,2.4,479.606,5.3,479.606z"
    />
    <path
      d="M406.8,409.706c1.9,3.3,6.2,4.4,9.5,2.5l17.4-10.1v20.1c0,3.8,3.1,6.9,6.9,6.9s6.9-3.1,6.9-6.9v-20.1l17.4,10.1
			c3.3,1.9,7.6,0.8,9.5-2.5s0.8-7.6-2.5-9.5l-17.5-10.2l17.5-10.2c3.3-1.9,4.4-6.2,2.5-9.5s-6.2-4.4-9.5-2.5l-17.4,10.1v-20.1
			c0-3.8-3.1-6.9-6.9-6.9s-6.9,3.1-6.9,6.9v20.1l-17.4-10.1c-3.3-1.9-7.6-0.8-9.5,2.5s-0.8,7.6,2.5,9.5l17.5,10.2l-17.5,10.2
			C406,402.106,404.8,406.406,406.8,409.706z"
    />
  </svg>
</template>
