<template>
  <div id="app">
    <component :is="this.$route.meta.layout || 'div'">
      <router-view />
    </component>
    <Loading v-if="$store.getters.isLoading" />
    <Alert />
    <ConfirmDialog v-if="$store.getters.isConfirmDialog" v-bind="popupConfirmAttr" />
  </div>
</template>

<script>
import Loading from "@Components/layouts/Loading";
import Alert from "@Components/layouts/Alert";
import ConfirmDialog from "@Components/layouts/ConfirmDialog";
import { onBeforeMount, watch, reactive } from "vue";
import { logout } from "@Libs/hooks/useAuth";
import { getUserInfo } from "@/api/user/user-repository";
import { isLoggedIn } from "@Libs/hooks/useAuth";
import { useRouter } from "vue-router";
import { useStore } from "vuex";
import { empty } from "@/utils";
export default {
  name: "App",
  components: {
    Loading,
    Alert,
    ConfirmDialog,
  },
  setup() {
    const router = useRouter();
    const store = useStore();
    onBeforeMount(() => getProfile(router));
    const popupConfirmAttr = reactive({});

    watch(
      () => store.getters.eventListenConfirmDialog,
      value => {
        if (!empty(value)) {
          popupConfirmAttr.eventListen = value;
        } else {
          delete popupConfirmAttr["eventListen"];
        }
      },
    );

    watch(
      () => store.getters.customCSSConfirmDialog,
      value => {
        if (!empty(value)) {
          popupConfirmAttr.customCSS = value;
        } else {
          delete popupConfirmAttr["customCSS"];
        }
      },
    );

    async function getProfile(router) {
      try {
        store.dispatch("updateLoadingText", "Getting data");
        store.dispatch("toggleLoading");
        if (isLoggedIn()) {
          const response = await getUserInfo();
          if (response.status === 401) {
            throw new Error("INVALID");
          }
        } else {
          throw new Error("INVALID");
        }
      } catch (error) {
        console.error({ error });
        await logout();
        router.push({ name: "Login" });
      } finally {
        store.dispatch("closeLoading");
      }
    }
    return {
      popupConfirmAttr,
    };
  },
};
</script>
