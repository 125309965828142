import moment from "moment";
import momentT from "moment-timezone";
import { isNumber, isString, sortBy } from "lodash";

export function formatDateUnix(value) {
  if (isNumber(value)) {
    return moment(value).format("YYYY/MM/DD");
  }
}
export function formatTimeUnix(value, isGetSecond = false) {
  if (isNumber(value)) {
    return moment(value).format(isGetSecond ? "HH:mm:ss" : "HH:mm");
  }
}

export function formatDate(value, format = "YYYY/MM/DD") {
  let date = new Date();
  if (value) {
    date = new Date(String(value));
  }
  return moment(date).format(format);
}

export function formatDateTime(value) {
  if (value) {
    return moment(isString(value) ? new Date(String(value)) : value).format("YYYY-MM-DD HH:mm");
  }
}

export function formatTime(value, isGetSecond = false) {
  if (value) {
    return moment(new Date(String(value))).format(isGetSecond ? "HH:mm:ss" : "HH:mm");
  }
}

export function getMomentT(date) {
  if (date) {
    return momentT(date).tz("UTC");
  }
  return momentT.tz("UTC");
}

export function formatDateTimeWithTz(value, timezone, format) {
  if (value) {
    return momentT(isString(value) ? new Date(String(value)) : value)
      .tz(timezone)
      .format(format);
  }
}

export function getMomentTimezoneList() {
  const timeZones = momentT.tz.names();
  let offsetTmz = [];

  for (let i in timeZones) {
    offsetTmz.push({
      label: `(GMT${moment.tz(timeZones[i]).format("Z")}) ${timeZones[i]}`,
      value: timeZones[i],
    });
  }
  return sortBy(offsetTmz, "label");
}
