<template>
  <section class="h-page-sm md:h-page overflow-y-auto bg-white dark:bg-dark-bgSecondary rounded-xl shadow-2xl p-4 md:p-5 lg:p-10">
    <div class="block md:flex justify-between">
      <h1 class="h1-Alternative uppercase text-center mb-3 md:mb-0">CSS help</h1>
    </div>

    <div class="mt-5 lg:mt-10">
      <div class="bg-white dark:bg-dark-bgSecondary border-t-2 border-grey-light dark:border-dark-textSecondary">
        <div class="relative flex flex-col min-w-0 break-words w-full">
          <div class="flex-auto dark:text-dark-textPrimary pt-6">
            <VeeForm v-slot="{ errors, handleSubmit }" :validation-schema="schema" as="div" class="flex flex-col font-fs-elliot-pro py-5">
              <form @submit="handleSubmit($event, onSubmit)" ref="form">
                <div class="flex flex-col mb-7 lg:mb-8">
                  <div class="w-full">
                    <Field
                      id="content"
                      v-model="payload.content"
                      name="content"
                      as="textarea"
                      rows="8"
                      :class="{
                        'text-danger border border-danger': errors.content,
                      }"
                      class="
                        block
                        w-full
                        appearance-none
                        bg-off-white-1
                        dark:bg-dark-bgPrimary
                        text-xs
                        md:text-lg
                        text-black-1
                        dark:text-dark-textPrimary
                        border border-grey-mid
                        focus:ring-magenta focus:border-magenta
                        rounded
                        py-2
                        md:py-3
                        px-2
                        md:px-4
                        mb-3
                      "
                    />
                    <p class="text-danger dark:text-danger text-xs md:text-base italic">
                      {{ errors.content }}
                    </p>
                  </div>
                </div>
                <p v-if="!empty(errorMessage)" class="text-danger my-2" v-html="errorMessage" />
                <button class="button_large-Alternative uppercase bg-magenta border rounded-full py-3 lg:p-4 focus:outline-none">Update</button>
              </form>
            </VeeForm>
          </div>
        </div>
      </div>
    </div>
  </section>
</template>

<script>
import { reactive, watch } from "vue";
import { useStore } from "vuex";
import { Form as VeeForm, Field } from "vee-validate";
import * as Yup from "yup";

import { sleep } from "@Helpers/sleep";

import { updateCssHelper } from "@API/event/event-repository";
import { empty } from "@/utils";
export default {
  name: "ResetPassword",
  components: {
    VeeForm,
    Field,
  },
  setup() {
    const payload = reactive({});
    const store = useStore();
    const schema = Yup.object().shape({
      content: Yup.string().required("CSS content is required"),
    });
    if (!empty(store.getters.getCssHelper)) {
      payload.content = store.getters.getCssHelper;
    }

    return {
      schema,
      payload,
    };
  },
  data() {
    return {
      errorMessage: "",
    };
  },
  methods: {
    empty,
    async onSubmit() {
      try {
        this.$store.dispatch("updateLoadingText", "Updating CSS Helper");
        this.$store.dispatch("toggleLoading");
        this.errorMessage = "";
        const result = await updateCssHelper(this.payload);
        this.$store.dispatch("setCssHelper", this.payload.content);

        // Show Alert
        this.$store.dispatch("toggleAlert", {
          isAlert: true,
          status: result.success,
          title: result.success ? "Success!" : "Failed!",
          message: result.message,
        });
        if (!result.success) {
          this.errorMessage = result.message;
        }
      } catch (error) {
        console.log({ error });
        this.errorMessage = "Update CSS helper error!";
        this.$store.dispatch("toggleAlert", {
          isAlert: true,
          status: false,
          title: "Error!",
          message: this.errorMessage,
        });
      } finally {
        this.$store.dispatch("closeLoading");

        await sleep(5000);
        // Hide Alert
        this.$store.dispatch("toggleAlert", {
          isAlert: false,
        });
      }
    },
  },
};
</script>
