<template>
  <section class="bg-grey-light font-sans">
    <div class="container w-full md:max-w-3xl mx-auto py-20">
      <div class="w-full px-4 md:px-6 text-xl text-gray-800 leading-normal" style="font-family: Georgia, serif">
        <div class="font-sans">
          <h1 class="font-bold font-sans break-normal text-center text-black-1 pt-6 pb-2 text-3xl md:text-4xl underline">TERMS AND CONDITIONS</h1>
        </div>

        <p class="py-6 leading-1.5 tracking-normal">
          These terms and conditions (the "Terms and Conditions") govern the use of <b><a href="https://isilive.ca/" target="_blank">www.iSiLIVE.ca</a></b> (the "Site"). This Site is owned and operated by iSiLIVE. This Site is a news or media
          website.
        </p>

        <ol>
          <li class="py-3 leading-1.5 tracking-normal">By using this Site, you indicate that you have read and understand these Terms and Conditions and agree to abide by them at all times.</li>
          <li class="py-3 leading-1.5 tracking-normal">
            THESE TERMS AND CONDITIONS CONTAIN A DISPUTE RESOLUTION CLAUSE THAT IMPACTS YOUR RIGHTS ABOUT HOW TO RESOLVE DISPUTES. PLEASE READ IT CAREFULLY.
          </li>
          <li class="py-3 leading-1.5 tracking-normal">The accuracy of the generated document on this website is not legally binding. Use at your own risk.</li>
        </ol>

        <b class="my-6 block underline">Intellectual Property</b>
        <p>
          All content published and made available on our Site is the property of iSiLIVE and the Site's creators. This includes, but is not limited to images, text, logos, documents, downloadable
          files and anything that contributes to the composition of our Site.
        </p>

        <b class="my-6 block underline">User Contributions</b>
        <p>Users may post the following information on our Site:</p>
        <p class="my-4">- Public comments.</p>
        <p>By posting publicly on our Site, you agree not to act illegally or violate these Terms and Conditions.</p>
        <b class="my-6 block underline">Accounts</b>
        <p>When you create an account on our Site, you agree to the following:</p>
        <p class="my-4">1. You are solely responsible for your account and the security and privacy of your account, including passwords or sensitive information attached to that account; and</p>
        <p class="my-4">2. All personal information you provide to us through your account is up to date, accurate, and truthful and that you will update your personal information if it changes.</p>
        <p>We reserve the right to suspend or terminate your account if you are using our Site illegally or if you violate these Terms and Conditions.</p>
        <b class="my-6 block underline">Sale of Goods And Services</b>
        <p>These Terms and Conditions govern the sale of goods and services available on our Site.</p>
        <p class="my-3">The following goods are available on our Site:</p>
        <p class="my-4">- Broadcast hardware; and</p>
        <p class="my-4">- Transmission hardware.</p>
        <p class="my-3">The following services are available on our Site:</p>
        <p class="my-4">- Live broadcasting;</p>
        <p class="my-4">- REMI; and</p>
        <p class="my-4">- Streaming.</p>
        <p class="my-3">The services will be paid for in full when the services are ordered.</p>
        <p class="py-4">
          These Terms and Conditions apply to all the goods and services that are displayed on our Site at the time you access it. This includes all products listed as being out of stock. All
          information, descriptions, or images that we provide about our goods and services are as accurate as possible. However, we are not legally bound by such information, descriptions, or images
          as we cannot guarantee the accuracy of all goods and services we provide. You agree to purchase goods and services from our Site at your own risk.
        </p>
        <p class="py-4">
          We reserve the right to modify, reject or cancel your order whenever it becomes necessary. If we cancel your order and have already processed your payment, we will give you a refund equal to
          the amount you paid. You agree that it is your responsibility to monitor your payment instrument to verify receipt of any refund.
        </p>
        <b class="my-6 block underline">User Goods and Services</b>
        <p>
          Our Site allows users to sell goods and services. We do not assume any responsibility for the goods and services users sell on our Site. We cannot guarantee the quality or accuracy of any
          goods and services sold by users on our Site. However, if we are made aware that a user is violating these Terms and Conditions, we reserve the right to suspend or prohibit the user from
          selling goods and services on our Site.
        </p>
        <b class="my-6 block underline">Subscriptions</b>
        <p>Your subscription automatically renews and you will be automatically billed until we receive notification that you want to cancel the subscription.</p>
        <p class="my-6">To cancel your subscription, please follow these steps: 90 day cancellation.</p>
        <b class="my-6 block underline">Payments</b>
        <p>We accept the following payment methods on our Site:</p>
        <p class="py-4">- Credit Card; and</p>
        <p class="py-4">- PayPal.</p>
        <p class="py-4">
          When you provide us with your payment information, you authorize our use of and access to the payment instrument you have chosen to use. By providing us with your payment information, you
          authorize us to charge the amount due to this payment instrument.
        </p>
        <p class="py-4">If we believe your payment has violated any law or these Terms and Conditions, we reserve the right to cancel or reverse your transaction.</p>
        <b class="my-6 block underline">Shipping and Delivery</b>
        <p>When you purchase goods from our Site, the goods will be delivered through one of the following methods:</p>
        <p class="py-4">- Standard delivery (5-7 days) unless courier account provided..</p>
        <p class="py-4">
          Delivery will take place as soon as reasonably possible, depending on the delivery method selected. Delivery times may vary due to unforseen circumstances. Please note that delivery times do
          not include weekends and statutory holidays.
        </p>
        <p class="py-4">You will be required to pay delivery charges in addition to the price for the goods you purchase.</p>
        <p class="py-4">
          If you purchase goods from us for delivery to a destination outside Canada your purchase may be subject to import duties and taxes applied by the destination country. You are responsible for
          paying any such duties or taxes. Please contact your local customs office for more information before making a purchase. We are not responsible for the payment of any such duties or taxes
          and are not liable for any failure by you to pay them.
        </p>
        <p class="py-4">
          You are required to provide us with a complete and accurate delivery address, including the name of the recipient. We are not liable for the delivery of your goods to the wrong address or
          wrong person as a result of you providing us with inaccurate or incomplete information.
        </p>
        <b class="mt-6 block underline">Refunds</b>
        <p class="underline">Refunds for Goods</p>
        <p>Refund requests must be made within 10 days after receipt of your goods.</p>
        <p>We accept refund requests for goods sold on our Site for any of the following reasons:</p>
        <p class="py-4">- Good is broken.</p>
        <p class="py-6">We accept refund requests for goods sold on our Site for any of the following reasons:</p>
        <p class="py-4">- Sale items; and</p>
        <p class="py-4">- Damaged goods.</p>
        <p class="mt-4 underline">Refunds for Services</p>
        <p class="mb-6">All services sold on our Site are non-refundable.</p>
        <b class="mt-6 block underline">Returns</b>
        <p class="mb-6">
          Returns can be made by mail. To return a good by mail, follow the following procedure: Pack your goods and attach the return label that was included in your package. Go to your nearest post
          office and pay the necessary postage.
        </p>
        <b class="mt-6 block underline">Consumer Protection Law</b>
        <p class="mb-6">
          Where the Consumer Protection Act, or any other consumer protection legislation in your jurisdiction applies and cannot be excluded, these Terms and Conditions will not limit your legal
          rights and remedies under that legislation. These Terms and Conditions will be read subject to the mandatory provisions of that legislation. If there is a conflict between these Terms and
          Conditions and that legislation, the mandatory provisions of the legislation will apply.
        </p>
        <b class="mt-6 block underline">Limitation of Liability</b>
        <p class="mb-6">
          iSiLIVE and our directors, officers, agents, employees, subsidiaries, and affiliates will not be liable for any actions, claims, losses, damages, liabilities and expenses including legal
          fees from your use of the Site.
        </p>
        <b class="mt-6 block underline">Indemnity</b>
        <p class="mb-6">
          Except where prohibited by law, by using this Site you indemnify and hold harmless iSiLIVE and our directors, officers, agents, employees, subsidiaries, and affiliates from any actions,
          claims, losses, damages, liabilities and expenses including legal fees arising out of your use of our Site or your violation of these Terms and Conditions.
        </p>
        <b class="mt-6 block underline">Applicable Law</b>
        <p class="mb-6">These Terms and Conditions are governed by the laws of the Province of Ontario.</p>
        <b class="mt-6 block underline">Dispute Resolution</b>
        <p class="mb-6">
          Subject to any exceptions specified in these Terms and Conditions, if you and iSiLIVE are unable to resolve any dispute through informal discussion, then you and iSiLIVE agree to submit the
          issue first before a non-binding mediator and to an arbitrator in the event that mediation fails. The decision of the arbitrator will be final and binding. Any mediator or arbitrator must be
          a neutral party acceptable to both you and iSiLIVE. The costs of any mediation or arbitration will be paid by the unsuccessful party.
        </p>
        <p class="mb-6">
          Notwithstanding any other provision in these Terms and Conditions, you and iSiLIVE agree that you both retain the right to bring an action in small claims court and to bring an action for
          injunctive relief or intellectual property infringement.
        </p>
        <b class="mt-6 block underline">Severability</b>
        <p class="mb-6">
          If at any time any of the provisions set forth in these Terms and Conditions are found to be inconsistent or invalid under applicable laws, those provisions will be deemed void and will be
          removed from these Terms and Conditions. All other provisions will not be affected by the removal and the rest of these Terms and Conditions will still be considered valid.
        </p>

        <b class="mt-6 block underline">Changes</b>
        <p class="mb-6">
          These Terms and Conditions may be amended from time to time in order to maintain compliance with the law and to reflect any changes to the way we operate our Site and the way we expect users
          to behave on our Site. We will notify users by email of changes to these Terms and Conditions or post a notice on our Site.
        </p>
        <b class="mt-6 block underline">Contact Details</b>
        <p class="mb-6">Please contact us if you have any questions or concerns. Our contact details are as follows:</p>
        <p>(888) 683-5727</p>
        <p>support@isilive.ca</p>
        <p>150 Terence Matthews Crescent</p>
        <p class="my-8">You can also contact us through the feedback form available on our Site.</p>
        <p class="text-right">Effective Date: 1st day of February, 2023</p>
      </div>
    </div>
  </section>
</template>

<script>
export default {
  name: "TermsOfUse",
};
</script>
