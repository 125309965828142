<template>
  <section class="home-page flex">
    <div
      class="w-34rem bg-white dark:bg-dark-bgSecondary rounded-2xl shadow-xl ml-auto mr-auto mt-52 py-9 px-7"
    >
      <h1 class="h1-Alternative uppercase">Welcome</h1>

      <div class="mt-5 mb-7">
        <p class="p1-Alternative">
          To access your events, please select 'events' from the menu, then
          select event you want to access
        </p>
      </div>
    </div>
  </section>
</template>

<script>
export default {
  name: "Home",
};
</script>
