import { createStore } from "vuex";

import theme from "./modules/theme";
import auth from "./modules/auth";
// import event from "./modules/event";
// import user from "./modules/user";
export default createStore({
  state: {},
  mutations: {},
  actions: {},
  modules: {
    theme,
    auth,
    // event,
    // user,
  },
});
