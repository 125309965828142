import AWSAppSyncClient from "aws-appsync";
// import { Auth } from "aws-amplify";

import { ApolloClient } from "@apollo/client";

const supportConfig = {
  url: process.env.VUE_APP_AWS_GRAPH_URL,
  region: process.env.VUE_APP_AWS_REGION,
  auth: {
    type: "API_KEY",
    apiKey: process.env.VUE_APP_AWS_API_KEY,
  },
  disableOffline: true,
};

const commentConfig = {
  url: process.env.VUE_APP_AWS_GRAPH_URL,
  region: process.env.VUE_APP_AWS_REGION,
  auth: {
    type: "API_KEY",
    apiKey: process.env.VUE_APP_AWS_API_KEY,
  },
  disableOffline: true,
};
const options = {
  defaultOptions: {
    watchQuery: {
      fetchPolicy: "cache-first",
    },
  },
};

const supportClient = new ApolloClient(
  new AWSAppSyncClient(supportConfig, options),
);
const commentClient = new ApolloClient(
  new AWSAppSyncClient(commentConfig, options),
);

export default {
  default: commentClient,
  commentClient,
  supportClient,
};
