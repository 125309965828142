import { createApp } from "vue";

import Amplify from "aws-amplify";
import awsmobile from "@/aws/aws-exports";
import apolloProvider from "@/aws/appsync";

import { ApolloClients } from "@vue/apollo-composable";
import mitt from "mitt";

import App from "./App.vue";
import router from "./router";
import store from "./store";
import {
  formatDate,
  formatTime,
  formatDateUnix,
  formatTimeUnix,
} from "./helpers/date";

window.info = store;
import "@/assets/styles/main.css";

Amplify.configure(awsmobile);

const app = createApp(App);
const emitter = mitt();

app.config.globalProperties.emitter = emitter;
app.config.globalProperties.$filters = {
  formatDate,
  formatTime,
  formatDateUnix,
  formatTimeUnix,
};

app.directive("outside", {
  beforeMount: function (el, binding) {
    // Define ourClickEventHandler
    const ourClickEventHandler = event => {
      if (!el.contains(event.target) && el !== event.target) {
        // as we are attaching an click event listern to the document (below)
        // ensure the events target is outside the element or a child of it
        binding.value(event); // before binding it
      }
    };
    // attached the handler to the element so we can remove it later easily
    el.__vueClickEventHandler__ = ourClickEventHandler;

    // attaching ourClickEventHandler to a listener on the document here
    document.addEventListener("click", ourClickEventHandler);
  },
  unmounted: function (el) {
    // Remove Event Listener
    document.removeEventListener("click", el.__vueClickEventHandler__);
  },
});

app.provide(ApolloClients, apolloProvider);
app.use(store).use(router).mount("#app");
