<template>
  <section class="flex h-screen bg-off-white-2 dark:bg-dark-bgPrimary font-roboto">
    <Sidebar />

    <div class="flex-1 flex flex-col overflow-hidden">
      <Header />

      <main class="flex-1 overflow-x-hidden overflow-y-auto">
        <div class="px-2 md:px-7 lg:px-11">
          <slot />
        </div>
      </main>
    </div>
  </section>
</template>

<script>
import { mapGetters } from "vuex";

import Sidebar from "@Components/layouts/Sidebar.vue";
import Header from "@Components/layouts/Header.vue";

export default {
  name: "MainLayout",
  components: {
    Sidebar,
    Header,
  },
  beforeMount() {
    this.$store.dispatch("initTheme");
  },
  computed: {
    ...mapGetters({ theme: "getTheme" }),
  },
  watch: {
    theme(newTheme) {
      newTheme === "light" ? document.querySelector("html").classList.remove("dark") : document.querySelector("html").classList.add("dark");
    },
  },
};
</script>
