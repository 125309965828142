import Repository from "@API/repository";

export const getUsers = async () => {
  const url = "user";
  const response = await Repository({
    url,
    method: "GET",
  });
  return response.data;
};

export const postUser = async data => {
  const url = "user";
  const response = await Repository({
    url,
    method: "POST",
    data,
  });
  return response.data;
};

export const putUser = async data => {
  const url = `user/${data.id}`;
  const response = await Repository({
    url,
    method: "PUT",
    data,
  });
  return response.data;
};

export const deleteUser = async payload => {
  const url = `user/${payload}`;
  const response = await Repository({
    url,
    method: "DELETE",
  });
  return response.data;
};

export const getUserInfo = async () => {
  const url = `get-me`;
  const response = await Repository({
    url,
    method: "GET",
  });
  return response.data;
};

export const resetPassword = async data => {
  const url = `user/set-password`;
  const response = await Repository({
    url,
    method: "PUT",
    data,
  });
  return response.data;
};
